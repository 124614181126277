exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-become-a-partner-tsx": () => import("./../../../src/pages/become-a-partner.tsx" /* webpackChunkName: "component---src-pages-become-a-partner-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-tsx": () => import("./../../../src/pages/investors.tsx" /* webpackChunkName: "component---src-pages-investors-tsx" */),
  "component---src-pages-platform-index-tsx": () => import("./../../../src/pages/platform/index.tsx" /* webpackChunkName: "component---src-pages-platform-index-tsx" */),
  "component---src-pages-platform-login-tsx": () => import("./../../../src/pages/platform/login.tsx" /* webpackChunkName: "component---src-pages-platform-login-tsx" */),
  "component---src-pages-platform-product-index-tsx": () => import("./../../../src/pages/platform/product/index.tsx" /* webpackChunkName: "component---src-pages-platform-product-index-tsx" */),
  "component---src-pages-platform-providers-index-tsx": () => import("./../../../src/pages/platform/providers/index.tsx" /* webpackChunkName: "component---src-pages-platform-providers-index-tsx" */),
  "component---src-pages-platform-user-index-tsx": () => import("./../../../src/pages/platform/user/index.tsx" /* webpackChunkName: "component---src-pages-platform-user-index-tsx" */),
  "component---src-pages-platform-user-orders-tsx": () => import("./../../../src/pages/platform/user/orders.tsx" /* webpackChunkName: "component---src-pages-platform-user-orders-tsx" */),
  "component---src-pages-platform-user-payments-tsx": () => import("./../../../src/pages/platform/user/payments.tsx" /* webpackChunkName: "component---src-pages-platform-user-payments-tsx" */),
  "component---src-pages-platform-user-products-create-tsx": () => import("./../../../src/pages/platform/user/products/create.tsx" /* webpackChunkName: "component---src-pages-platform-user-products-create-tsx" */),
  "component---src-pages-platform-user-products-index-tsx": () => import("./../../../src/pages/platform/user/products/index.tsx" /* webpackChunkName: "component---src-pages-platform-user-products-index-tsx" */),
  "component---src-pages-proveedores-dashboard-js": () => import("./../../../src/pages/proveedores/dashboard.js" /* webpackChunkName: "component---src-pages-proveedores-dashboard-js" */),
  "component---src-pages-proveedores-ordenes-[id]-js": () => import("./../../../src/pages/proveedores/ordenes/[id].js" /* webpackChunkName: "component---src-pages-proveedores-ordenes-[id]-js" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */)
}

